<template>
  <div id="addAnnoucementModal">
    <CModal
      :show.sync="showModal"
      size="lg"
      :centered="true"
      :closeOnBackdrop="false"
    >
      <CForm>
        <CSelect
          label="Target Type"
          :value.sync="typeSelected"
          :options="options"
        />
        <CInput
          label="Title"
          placeholder="Enter title..."
          v-model="inputTitle"
          required
          was-validated
        />
        <CTextarea
          label="Content"
          v-model="inputContent"
          placeholder="Enter content..."
          rows="4"
          required
          was-validated
        />
      </CForm>
      <template #header>
        <h5 class="modal-title">Add Announcement</h5>
        <CButtonClose @click="closeModal" />
      </template>
      <template #footer>
        <CButton @click="closeModal" color="secondary">Cancel</CButton>
        <CButton @click="onAddAnnouncement" color="primary">Add</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
export default {
  props: {
    showModal: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      inputTitle: '',
      inputContent: '',
      options: [
        { value: 'all', label: 'All' },
        { value: 'provider', label: 'Provider' },
        { value: 'consumer', label: 'Consumer' },
      ],
      typeSelected: 'all',
    };
  },
  methods: {
    closeModal() {
      this.inputTitle = '';
      this.inputContent = '';
      this.typeSelected = 'all';

      this.$emit('close-modal');
    },
    onAddAnnouncement() {
      this.$emit('add-announcement', {
        title: this.inputTitle,
        content: this.inputContent,
        target_type: this.typeSelected,
      });
    },
  },
  name: 'AddAnnoucementModal',
};
</script>
