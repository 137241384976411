<template>
  <CRow>
    <CCol col>
      <CCard>
        <CCardHeader>
          <CRow class="justify-content-between px-3">
            <h5>Announcements</h5>

            <CButton
              size="sm"
              @click="isShowAddAnnouncementModalComponent = true"
              color="success"
              >Add Announcement</CButton
            >
          </CRow>
        </CCardHeader>
        <CCardBody>
          <CDataTable
            hover
            striped
            border
            :items="announcements"
            :fields="fields"
            :items-per-page="25"
            clickable-rows
            :active-page="activePage"
            :pagination="{ doubleArrows: false, align: 'center' }"
            @page-change="pageChange"
          >
            <td slot="created_at" slot-scope="{ item }">
              {{ item.created_at | moment(datetimeFormat) }}
            </td>
          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>
    <AddAnnoucementModal
      @add-announcement="onAddAnnouncement"
      @close-modal="onCloseAddAnnouncementModalComponent"
      :showModal="isShowAddAnnouncementModalComponent"
    />
  </CRow>
</template>

<script>
import { mapGetters } from 'vuex';
import { ACTION_FETCH_ANNOUNCEMENTS } from '@/store/actions.type';
import { Common } from '@/constants';
import AddAnnoucementModal from '@/components/modals/AddAnnoucementModal';
import AnnouncementProxy from '@/proxies/announcement.proxy';

export default {
  name: 'Announcements',
  components: {
    AddAnnoucementModal,
  },
  data() {
    return {
      isShowAddAnnouncementModalComponent: false,
      datetimeFormat: Common.datetimeFormat,
      fields: [
        { key: 'id', label: 'ID' },
        { key: 'title', label: 'Title' },
        { key: 'content', label: 'Content' },
        { key: 'target_type', label: 'TargetType' },
        { key: 'created_at', label: 'Created Date' },
      ],
      activePage: 1,
    };
  },
  mounted() {
    this.$store.dispatch(ACTION_FETCH_ANNOUNCEMENTS);
  },
  computed: {
    ...mapGetters(['announcements']),
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
  },
  methods: {
    pageChange(val) {
      this.$router.push({ query: { page: val } });
    },
    onCloseAddAnnouncementModalComponent() {
      this.isShowAddAnnouncementModalComponent = false;
    },
    onAddAnnouncement(data) {
      AnnouncementProxy.create(data).then(() => {
        this.isShowAddAnnouncementModalComponent = false;
        this.$toast.success(`Add announcement successfully`, {
          duration: 5000,
        });

        this.$store.dispatch(ACTION_FETCH_ANNOUNCEMENTS, {});
      });
    },
  },
};
</script>
